import React, { ReactNode, useEffect } from "react"

const InstantPage = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])
  const parallax = (e: any) => {
    const distance = e.target?.scrollTop || 0

    const element = document.querySelector(".parallax") as HTMLImageElement
    if (element) {
      element.style.transform = `translateY(${Math.min(
        distance * 0.3,
        200,
      )}px) scale(${Math.min(1 + distance * 0.0008, 1.3)})`
      element.style.filter = `blur(${Math.min(distance * 0.1, 15)}px)`
    }
  }
  return (
    <div
      onScroll={parallax}
      style={{
        position: "fixed",
        overflow: "scroll",
        width: "100%",
        height: "100%",
        zIndex: 9,
        pointerEvents: "all",
        overscrollBehavior: "none",
        overflowX: "hidden",
        maxWidth: "100vw",
      }}
    >
      <div>{children}</div>
    </div>
  )
}

export default InstantPage
